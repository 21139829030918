import { graphql, Link } from 'gatsby';
import React from 'react';

import Recetas from '../components/HomePage/Recetas';
import Advices from '../components/HomePage/Advices';
import Layout from '../components/Layout';
import Posts from '../components/Posts';
import SeoPage from '../components/SEO/SeoPage';
import Top10Posts from '../components/Top10Posts';
import Top10Recipes from '../components/Top10Recipes';
import HeroCta from '../components/Subscribe/HeroCta';

const IndexPage = props => {
  const { data } = props;

  const {
    allPrismicRecipes: { edges: recipes },
  } = data;
  const { categoryPosts, deAuthor } = props.data;
  const AllPosts = categoryPosts.edges.map(post => {
    if (!post.node.data.author.document) {
      post.node.data.author = deAuthor;
      post.node.data.author.slug = 'dediabetes';
    } else {
      let author_id = post.node.data.author.slug;
      post.node.data.author = post.node.data.author.document;
      post.node.data.author.slug = author_id;
    }
    return post;
  });

  return (
    <Layout>
      <SeoPage
        title="Información y Herramientas para la Diabetes"
        description="Tu fuente de información para el cuidado de la diabetes. Te brindamos herramientas y educación para el manejo de la diabetes y sus complicaciones"
        type="website"
      />
      <HeroCta />
      {/* <HeroOptin/> */}

      <div className="mx-autoleading-losse my-6 lg:mx-24">
        {/* <Hero showPerson /> */}
        <div className="mx-autoleading-losse my-6 w-full container mx-auto">
          <Advices advices={data.allPrismicAdvice} />
        </div>
        <Posts
          posts={AllPosts}
          title="Ultimos Artículos"
          className="grid grid-cols-3"
        />
        <div className="flex items-center justify-center p-8 text-2xl">
          <button className="border-2 border-themeBrandColor rounded hover:bg-themeBrandColor hover:text-white p-4 mb-2 mr-2 focus:outline-none">
            <Link to="/articulos/">Mas Artículos</Link>
          </button>
        </div>

        <div className="mx-autoleading-losse my-6 w-full container mx-auto">
          <Recetas recipes={recipes} className=""></Recetas>
          <Top10Posts />
          <Top10Recipes />
        </div>
      </div>
      {/* <div className="md:flex flex-row justify-center m-6 hidden md:visible">
        <TopHomepage />
      </div>
      <div className="flex flex-row justify-center mt-6 md:hidden ">
        <TopHomepageMob />
      </div>



      {/* <div className="mx-autoleading-losse my-6 container mx-auto">
      <Hero showPerson/>
          <Posts posts={AllPosts} title="Ultimos Artículos" className="grid grid-cols-3" />

          <div className="flex items-center justify-center p-8 text-2xl">
        <button className="border-2 border-themeBrandColor rounded hover:bg-themeBrandColor hover:text-white p-4 mb-2 mr-2 focus:outline-none">
          <Link to="/articles/">Mas Artículos</Link>
        </button>
      </div>

      <div className="mx-autoleading-losse my-6 w-full container mx-auto">

        <Recetas recipes={recipes} className=""></Recetas>
        <Top10Posts />
        <Top10Recipes/>

      </div>

      </div> */}
    </Layout>
  );
};

export const query = graphql`
  {
    deAuthor: prismicAuthor(uid: { eq: "dediabetes" }) {
      data {
        name {
          text
        }
        description_short {
          text
        }
        headshot {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
    categoryPosts: allPrismicBlogPost(
      limit: 6
      sort: { fields: data___release_date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            description {
              text
            }
            featured_image {
              gatsbyImageData(layout: CONSTRAINED)
            }
            author {
              slug: uid
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name {
                      text
                    }
                    description_short {
                      text
                    }
                    headshot {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
            }
            type
            release_date(formatString: "MMM DD, YYYY", locale: "es-ES")
            categories {
              category {
                slug: uid
                document {
                  ... on PrismicCatego {
                    uid
                    id
                    data {
                      name {
                        text
                      }
                      label {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicRecipes(
      filter: { lang: { eq: "es-es" } }
      limit: 6
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          slug: uid
          data {
            title: title1 {
              text
            }
            image {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
    allPrismicAdvice(limit: 6) {
      nodes {
        slug: uid
        data {
          title: title_advice {
            text
          }
          description: description_advice {
            text
          }
          cat_group {
            category {
              slug
              url
              document {
                ... on PrismicCatego {
                  id
                  data {
                    label {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
