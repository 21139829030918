import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const PostBox = styled.div`
  min-width: 250px;
  max-width: ${({ heroBox }) => (heroBox ? '400px' : '100%')};

  :first-of-type {
    margin-top: 0;
  }

  @media (min-width: 768px) {
    min-width: 300px;
  }

  @media (min-width: 1280px) {
    min-width: 350px;
  }
`;

const Consejos = ({ advices }) => {
  console.log('Advices in home ---> ', advices);
  return (
    <PostBox className="bg-white leading-3 mx-1 md:mx-3 lg:mx-8 mt-4">
      <h2 className="text-center text-4xl">
        Consejos para el Cuidado de la Diabetes
      </h2>
      <div className="">
        <article className="grid md:grid-cols-2 lg:grid-cols-3  gap-12 border-gray-300">
          {advices.nodes.map((item, index) => {
            return (
              <div
                key={index}
                className="bg-white leading-loose p-8 lg:p-4 items-stretch border-b-2"
              >
                <Link
                  to={`/consejos/${item.slug}/`}
                  className="h-16 text-lg font-bold hover:text-themeBrandColor"
                >
                  <div className="flex gap-2">
                    {item.data.cat_group.map(item => {
                      console.log({ item });
                      return (
                        <span
                          key={item.category.slug}
                          className="px-4 shadow text-sm rounded border bg-green-300  border-green-300"
                        >
                          {item.category.document.data.label.text}
                        </span>
                      );
                    })}
                  </div>
                  <h3 className="mt-4 mb-2 text-lg font-bold">
                    {item.data.title.text}
                  </h3>
                  <p className="mt-0 text-sm">
                    {item.data.description.text.substring(0, 260)}
                    {' ...'}
                  </p>
                </Link>
              </div>
            );
          })}
        </article>
      </div>

      <div className="flex items-center justify-center p-8 text-2xl">
        <button className="border-2 border-themeBrandColor rounded hover:bg-themeBrandColor hover:text-white p-4 mb-2 mr-2 focus:outline-none">
          <Link to="/consejos/">Mas Consejos</Link>
        </button>
      </div>
    </PostBox>
  );
};

export default Consejos;
