import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { CircularProgress } from '@material-ui/core';
import { useSendForm } from '../../utils/useSendForm';
import MessagePanel from './MessagePanel';

const query = graphql`
  {
    file(relativePath: { eq: "hero-image.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

const HeroCta = () => {
  const { file } = useStaticQuery(query);
  const {
    email,
    sending,
    error,
    success,
    onEmailChange,
    submitForm,
  } = useSendForm();

  return (
    <div className="flex flex-col bg-themeLighter">
      <div className="md:mx-auto md:container">
        <div className="pt-6 md:pt-28">
          <div className="container mx-auto">
            <div className="flex flex-wrap items-center pb-12">
              <div className="md:w-1/2 lg:w-2/3 w-full">
                <div className="ml-2 lg:ml-24">
                  <h2 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                    Subscríbete para recibir GRATIS un menú para diabetes de 7
                    días saludable, delicioso y fácil de preparar
                  </h2>
                  <p className="mb-8 leading-relaxed">
                    Por que sabemos que vivir con diabetes no es fácil. Nuestra
                    misión es ayudar a cuantas personas sea posible a mejorar su
                    dieta y vivir una vida plena.
                  </p>
                  <div className="flex flex-row justify-start w-full max-w-md shadow-xl border-t border-b border-gray-200">
                    <form
                      action=""
                      className="flex flex-row justify-start w-full max-w-md shadow-xl border-t border-b border-gray-200"
                      onSubmit={submitForm}
                    >
                      <input
                        className="border-l-4 border-red-700 bg-white focus:outline-none px-4 w-full m-0"
                        type="email"
                        name="email"
                        id="email-input"
                        placeholder="Email"
                        value={email}
                        onChange={onEmailChange}
                        disabled={sending}
                      />
                      <button
                        className="inline-flex text-black py-2 px-6 focus:outline-none text-lg m-0 h-12 rounded border-2 border-themeBrandColor border-solid bg-themeBrandColor hover:bg-white text-white hover:text-themeBrandColor "
                        type="submit"
                      >
                        Suscribirme
                      </button>
                    </form>
                  </div>
                  <MessagePanel
                    error={error}
                    sending={sending}
                    success={success}
                  />
                </div>
              </div>
              <div className="lg:w-1/3 md:w-1/2 w-full relative h-96 flex items-end justify-center">
                <div className="relative z-10 p-2 w-10/12">
                  <GatsbyImage
                    image={file.childImageSharp.gatsbyImageData}
                    alt="hero image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroCta;
