import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';
import PostDisplay from './PostDisplay';


const Heading = styled.div`
  width: 10rem;

  @media (min-width: 640px) {
    width: 11rem;
  }

  @media (min-width: 768px) {
    width: 14.5rem;
  }
`;

const query = graphql`
  { 
    deAuthor : prismicAuthor(uid: {eq: "dediabetes"}) {
      data {
        name {
          text
        }
        description_short {
          text
        }
        headshot {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    TotalPosts: allPrismicBlogPost(filter: {data: {author: {uid: {ne: null}}}, lang: {eq: "es-es"}}) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            categories {
              category {
                slug : uid
                document {
                  ... on PrismicCatego {
                    uid
                    id
                    data {
                      name {
                        text
                      }
                      label {
                        text
                      }
                    }
                  }
                }
              }
            }
            type
            release_date(formatString: "MMM DD, YYYY", locale: "es-ES")
            author {
              slug : uid
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name {
                      text
                    }
                    description_short {
                      text
                    }
                    headshot {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
            }
            featured_image {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
    allPageViews {
      nodes {
        id
        last30DayViews
      }
    }
  }
`;

export default function Top10Posts() {
  const {
    TotalPosts,
    deAuthor,
    allPageViews: { nodes: allViews },
  } = useStaticQuery(query);
  //const data = useStaticQuery(query);
  //const { TotalPosts, deAuthor } = data
  const allPosts = TotalPosts.edges
  
  function* filter(array, condition, maxSize) {
    if (!maxSize || maxSize > array.length) {
      maxSize = array.length;
    }
    let count = 0;
    let i = 0;
    while (count < maxSize && i < array.length) {
      const check = condition(array[i]);
      if (check) {
        yield check;
        count++;
      }
      i++;
    }
  }

  const postsAll = Array.from(
    filter(
      allViews,
      all => {
        const post = allPosts.filter(
          post => post.node.uid === all.id.replace('/', '')
        );
        if (post.length > 0) {
          return post[0];
        }
        return false;
      },
      10
    )
  );

  const posts = postsAll.map(post => {
    if(!post.node.data.author.document){
      post.node.data.author = deAuthor
      post.node.data.author.slug = 'dediabetes'
    }
    else
    {
      let author_id = post.node.data.author.slug
      post.node.data.author = post.node.data.author.document
      post.node.data.author.slug = author_id
    }
    return post
  })
  return (
    <div className="flex mx-1 md:mx-4 lg:mx-8 mt-8">
      <Heading className="bg-themeBrandColor p-4 sm:p-6 flex">
        <h2 className="m-auto text-lg text-white">Artículos Más Populares</h2>
      </Heading>
      <ScrollContainer className="flex" vertical={false}>
        {posts &&
          posts.map((post, index) => (
            <PostDisplay key={index} post={post} heroBox />
          ))}
      </ScrollContainer>
    </div>
  );
}
